import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(null);

    //Configuracion global de axios para validar token.
    /* axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if(error.response.status == 401){
          logout(true);
          Snackbar.show({
            duration : Snackbar.LENGTH_LONG,
            text : "Tu sesión ha expirado, por favor ingresa nuevamente"
          });
        }
        return error;
      }
    ); */
  
    const logout = () => {
      window.open(`${process.env.REACT_APP_SERVER_URL}/auth/logout`,"_self");
    }
  
    const getUser = async () => {
      
      fetch(`${process.env.REACT_APP_SERVER_URL}/auth/login/success`,{
        method : 'GET',
        credentials : "include",
        headers : {
          "Accept": "application/json",
          "Content-Type" : "application/json",
          "Access-Control-Allow-Credentials" : true
        }
      })
      .then( response => {
       
        if(response.status === 200 ){
          return response.json();
        }
        throw new Error("Authentication failed");
      })
      .then(resObj => {
        console.log(resObj)
        setUser(resObj.user);
        
      })
      .catch(er => {
        console.error(er);
      })
      
    }

     
    useEffect(()=> {
      
    },[user]);
    
  
    useEffect(() => {
      setIsLoading(true);
      getUser()
      .then( _ => {
        setTimeout(() => {
          setIsLoading(false);
        },500)
        
      });
    }, []);
    
    

    return (
      <AuthContext.Provider value={{ user, isLoading, setIsLoading, logout}}>
        {children}
      </AuthContext.Provider>
    )
  }