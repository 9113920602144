import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { dark_cyan, darker_cyan,darker_cyan2 } from "../colors";
import Icon from "@mdi/react";
import { mdiEmailOutline, mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import logo from '../img/logo.trim.png';
import axios from "axios";
import { Link, useSearchParams } from "react-router-dom";


const Login = () => {
	const [step, setStep] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams({ 'step': 0 });
	const [verPass, setVerPass] = useState(false);
	const [userForm, setUserForm] = useState({
		username : '',
		password : ''
	});
	const [formErrors, setFormErrors] = useState({
		username : '',
		password : '',
		loginError : ''
	});



	const google = () => {
		window.open(`${process.env.REACT_APP_SERVER_URL}/auth/google`, "_self");
	};

	const facebook = () => {
		window.open(`${process.env.REACT_APP_SERVER_URL}/auth/facebook`, "_self");
	};

	const mail = () => {

		setFormErrors({
			username : '',
			password : '',
			loginError : ''
		})

		let valid = true;
		let errors = {...formErrors};
		if(userForm.username == ""){
			valid = false;
			errors.username = 'Ingrese un mail válido.';
		}

		if(userForm.password == ""){
			valid = false;
			errors.password = 'Ingrese la contraseña.';
		}

		if(!valid){
			setFormErrors(errors)
			return;
		}

		axios({
			method : 'POST',
			url :`${process.env.REACT_APP_SERVER_URL}/auth/local`,
			data : {
				username : userForm.username,
				password : userForm.password
			},
			withCredentials : true	
		})
		.then(res => {
			console.log(res)
			if(res){
				window.location.replace('/')
			}
		})
		.catch(er => {
			console.log(er)
			if(er.response.status === 404){
				setFormErrors({username : '', password : '', loginError : 'Mail y/o contraseña incorrectos.'})
			}
		})
	};

	const handleUsernameChange = (e) => {
		setUserForm({...userForm, username : e.target.value})
	}

	const handlePassChange = (e) => {
		setUserForm({...userForm, password : e.target.value})
	}

	const SelectMetodo = () => {
		return(
			<>
			<Title>Elegí como ingresar a tu ciudad</Title>
			<LoginButton onClick={ () => setSearchParams({ 'step': 1 }) }>
				<LogoDiv>
					<StyledIcon path={mdiEmailOutline} size={1.5}></StyledIcon>
				</LogoDiv>
				<TextDiv>
					<b>Ingresar con email</b>
				</TextDiv>
			</LoginButton>

			<LoginButton onClick={google}>
				<LogoDiv>
					<SvgContainer>
						<svg aria-hidden="true" viewBox="0 0 18 18">
							<path
								fill="#4285F4"
								d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18Z"
							></path>
							<path
								fill="#34A853"
								d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17Z"
							></path>
							<path
								fill="#FBBC05"
								d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07Z"
							></path>
							<path
								fill="#EA4335"
								d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3Z"
							></path>
						</svg>
					</SvgContainer>
				</LogoDiv>
				<TextDiv>
					<b>Ingresar con Google</b>
				</TextDiv>
			</LoginButton>

			<LoginButton onClick={facebook}>
				<LogoDiv>
					<SvgContainer>
						<svg aria-hidden="true" viewBox="0 0 18 18">
							<path
								fill="#395697"
								d="M3 1a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3Zm6.55 16v-6.2H7.46V8.4h2.09V6.61c0-2.07 1.26-3.2 3.1-3.2.88 0 1.64.07 1.87.1v2.16h-1.29c-1 0-1.19.48-1.19 1.18V8.4h2.39l-.31 2.42h-2.08V17h-2.5Z"
							></path>
						</svg>
					</SvgContainer>
				</LogoDiv>

				<TextDiv>
					<b>Ingresar con Facebook</b>
				</TextDiv>
			</LoginButton>

			{/* <StyledP>¿Primera vez por acá? <StyledLink to="/registro"><Action> Creá una cuenta.</Action></StyledLink></StyledP> */}

			<StyledP>Lee nuestros <Action>términos y condiciones.</Action></StyledP>
			</>
		)
	}

	const LoginMail = () => {
		return(
			<>
			<Title>Completá los datos de tu cuenta</Title>
			
			<StyledLabel>Email</StyledLabel>
			<StyledInput >
				<input type={'text'} value={userForm.username} onChange = { handleUsernameChange}/>
			</StyledInput>
			<ErrorMsg>{formErrors.username}</ErrorMsg>
			<div><br></br></div>
			<StyledLabel>Contraseña</StyledLabel>
			<StyledInput >
				<input  type={(verPass)  ? 'text' : 'password'} value={userForm.password}  onChange = { handlePassChange}/>
				<LogoDiv>
					<StyledIcon path={(verPass) ? mdiEyeOutline : mdiEyeOffOutline } size={1.5} onClick={()=> { setVerPass(!verPass) }} ></StyledIcon>
				</LogoDiv>
			</StyledInput>
			<ErrorMsg>{formErrors.password}</ErrorMsg>

			<ContinuarButton onClick={mail}>Continuar</ContinuarButton>
			<ErrorMsg>{formErrors.loginError}</ErrorMsg>
			
			<div>
				<br />
			</div>
			<StyledP><StyledLink to="/olvideClave"><Action>¿Olvidaste tu contraseña?</Action></StyledLink></StyledP>

			<StyledP>¿Primera vez por acá? <StyledLink to="/registro"><Action> Creá una cuenta.</Action></StyledLink></StyledP>
			</>
		)
	}

	const renderStep = () => {
		switch(searchParams.get('step')){
			case '0':
				return SelectMetodo()
			case '1':
				return LoginMail()
			default:
				return SelectMetodo()
		}
	}

	return (
		<Container>
            <Logo src={logo}></Logo>
			{
				renderStep()
			}
			

		</Container>
	);
};

export default Login;

const Container = styled.div`
	display: flex;
	padding: 1rem;
	justify-content: center;
	flex-direction: column;
`;

const LoginButton = styled.div`
	border-radius: 0.5rem;
	border: 3px solid ${dark_cyan};
	background-color: white;
	color: #050505;
	padding: 1rem;
	display: flex;
	align-self: center;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
	justify-content: center;
	cursor: pointer;
	font-size: 1.6rem;
	font-family: "Space Grotesk", sans-serif;
    width : 30vw;
	max-width: 500px;
	position: relative;
	flex: 6;
	@media (max-width: 1040px) {
		width: 90vw;
        max-width: 90vw;
	}

	b {
	}
`;

const StyledIcon = styled(Icon)`
	margin-top: 4px;
	margin-right: 2rem;
`;

const SvgContainer = styled.div`
	margin-top: 1px;
	width: 2rem;
	height: 2rem;
	padding: 0;
	margin-right: 2rem;
`;

const LogoDiv = styled.div`
	display: flex;
	flex: 1.5;
	justify-content: flex-start;
    @media (min-width: 1300px) {
		flex: 2;
        justify-content: center;
	}
`;

const TextDiv = styled.div`
	display: flex;
	flex: 4.5;
	align-items: center;
	justify-content: flex-start;
    @media (min-width: 1300px) {
		flex: 4;
        justify-content: center;
	}
`;


const Action = styled.b`
	color : ${dark_cyan};
	cursor : pointer;
`;



const Logo = styled.img`
    width: 12rem;
    margin : 2rem;
    display : flex;
    align-self: center;
`;


const Title = styled.h2`
	text-align: center;
	color : black;
	font-weight: bold;
	margin-top: 2rem;
	margin-bottom : 2rem;
`;

const StyledP = styled.p`
	text-align: center;
	margin-top : 5px;
	margin-bottom: 5px;
	font-size: 1.2rem;
	
`;

const StyledLabel = styled.div`
	padding: 0rem;
	display: flex;
	align-self: center;
	justify-content: flex-start;
	color : gray;
	font-family: "Space Grotesk", sans-serif;
    width : 30vw;
	max-width: 500px;
	position: relative;
	@media (max-width: 1040px) {
		width: 90vw;
        max-width: 90vw;
	}
`;

const StyledInput = styled.div`
	border-radius: 0.5rem;
	border: 3px solid #BEBEBE;
	background-color: white;
	color: #050505;
	padding: 1rem;
	display: flex;
	align-self: center;
	justify-content: center;
	font-size: 1.6rem;
	font-family: "Space Grotesk", sans-serif;
    width : 30vw;
	max-width: 500px;
	position: relative;
	@media (max-width: 1040px) {
		width: 90vw;
        max-width: 90vw;
	}

	input{
		border : none;
		font-size: inherit;
		color : inherit;
		display : flex;
		flex : 5;
		&:hover, :active, :focus{
			border : none;
			border-color: transparent;
			outline: none;
			outline-offset: 0;
			box-shadow: none;
		};
	}
`;

const ContinuarButton = styled.button`
    border-radius :0.5rem;
    border-width : 0;
    background-color: ${darker_cyan2};
    color : white;
    padding : 1rem;
    display : flex;
    align-self: center;
    margin-bottom: 8px;
    margin-top: 8px;
    justify-content: center;
    cursor : pointer;
    font-weight : bold;
    font-size: 1.6rem;
    font-family: 'Space Grotesk', sans-serif;
    width : 30vw;
	max-width: 500px;
	position: relative;
    &:hover {
        background-color: ${darker_cyan};
    };
    @media (max-width: 1040px) {
		width : 90vw;
		max-width: 90vw;
	};
`;

const ErrorMsg = styled.div`
	padding: 0rem;
	display: flex;
	align-self: center;
	justify-content: flex-start;
	color : darkred;
	font-family: "Space Grotesk", sans-serif;
    width : 30vw;
	max-width: 500px;
	position: relative;
	@media (max-width: 1040px) {
		width: 90vw;
        max-width: 90vw;
	}
`;

const StyledLink = styled(Link)`
	text-decoration: none;
`;