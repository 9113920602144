import { createGlobalStyle } from "styled-components";
import { dark_cyan, gamboge } from "../colors";

const GlobalStyle = createGlobalStyle`
:root {
    color-scheme: light only;
}
*{
    margin : 0;
    padding : 0;
    box-sizing : border-box;
}

html{
    @media(max-width: 1700px){
        font-size : 75%;
    }
}

body{
    background : white;
    font-family: 'Space Grotesk', sans-serif;
    overflow-x: hidden;
    padding-bottom: 15vh;
}

h1{
    color : ${dark_cyan};
    font-weight: 700;
}

h2{
    color : ${dark_cyan};
    font-weight: 500;
}

h3{
    color : ${dark_cyan};
    font-weight: 300;
}

.button{
    font-weight:bold;
    font-family: 'Space Grotesk', sans-serif;
}

.mainPhoto{
    width : 50vw;
    border-radius: 0.5rem;
    align-self: center;
    display: flex;
    margin:auto;
    @media (max-width: 1300px) {
		width : 80vw;
	}
}
.leaflet-container {
  width: 100vw; 
  height: 60vh;
}
/* 
button{
    font-weight:bold;
    font-size: 1.1rem;
    cursor:pointer;
    padding:1rem 2rem;
    border: 3px solid #23d997;
    background:transparent;
    color:white;
    transition: all 0.5s ease;
    font-family: 'Inter', sans-serif;
    &:hover{
        background : #23d997;
        color: white;
    }

}

h2{
    font-weight:lighter;
    font-size:4rem;
}
h3{
    color:white;
}
h4{
    font-weight:bold;
    font-size: 2rem;
}
a{
    font-size: 1.1rem;
}
span{
    font-weight:bold;
    color :#23d997;
}
p{
    padding:3rem 0rem;
    color: #ccc;
    font-size: 1.4rem;
    line-height: 150%;
} */
`

export default GlobalStyle;