import { MapContainer, TileLayer } from "react-leaflet";
import React, { useState } from "react";
import styled from "styled-components";
import MapMarker from "./MapMarker";
import { darker_cyan, darker_cyan2 } from "../colors";
import SearchAddress from "./SearchAddress";
import SeleccionarUbicacion from "./SeleccionarUbicacion";

const Mapa = ({
	center,
	zoom,
	handleButton,
	step,
	setUbicacion,
	ubicacion,
}) => {
	const [resultados, setResultados] = useState([]);
	const [selectModalVisible, setSelectModalVisible] = useState(false);

    const buscarCallback = (resul) => {
        setResultados(resul);
        setSelectModalVisible(true);
    }
    
	return (
		<Container>
           
            <SeleccionarUbicacion visible={selectModalVisible} setVisible={setSelectModalVisible} items={resultados} selectItem={setUbicacion}/>
			<SearchAddress searchCallback={buscarCallback} />
			<StyledMapContainer
				className="map"
				center={center}
				zoom={zoom}
				scrollWheelZoom={false}
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<MapMarker coords={center} />
			</StyledMapContainer>
			{ ubicacion.direccion && <Dir>
                <b>Seleccionada: { ubicacion.direccion }</b>
                </Dir> 
            }
			<Botonera>
				<CancelButton onClick={() => handleButton(step - 1)}>
					{" "}
					Cancelar
				</CancelButton>
				{ubicacion.direccion && <SiguienteButton onClick={() => handleButton(step + 1)}>
					Continuar
				</SiguienteButton>}
			</Botonera>
		</Container>
	);
};

export default Mapa;

const StyledMapContainer = styled(MapContainer)`
	width: "100%";
	height: "100%";
`;

const Dir = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    margin-top : 1rem;
    color : ${darker_cyan}
`;

const Botonera = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0rem 1rem;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	z-index: 10;
	background-color: transparent;
`;

const Container = styled.div`
	position: relative;
`;

const Button = styled.button`
	border-radius: 0.5rem;
	border-width: 0;
	color: white;
	padding: 1rem;
	display: flex;
	align-self: center;
	margin-bottom: 8px;
	margin-top: 8px;
	justify-content: center;
	cursor: pointer;
	font-weight: bold;
	font-size: 1.5rem;
	font-family: "Space Grotesk", sans-serif;
	-webkit-tap-highlight-color: transparent; /* Remover el color de resaltado al tocar en moviles*/
`;

const SiguienteButton = styled(Button)`
	background-color: ${darker_cyan2};
	&:hover {
		background-color: ${darker_cyan};
	}
`;

const CancelButton = styled(Button)`
	color: black;
	background-color: white;
	&:hover {
		background-color: #bebebe;
	}
`;
