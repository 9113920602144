import React, {useContext} from "react";
//GlobalStyle
import GlobalStyle from "./components/GlobalStyle";

//Components
import Header from "./components/Header";
import Nav from "./components/Nav";
//Pages
import Home from "./pages/Home";
import Reclamos from "./pages/Reclamos";
import Avisos from "./pages/Avisos";
import Ayuda from "./pages/Ayuda";
import NuevoReclamo from "./pages/NuevoReclamo";
import Login from "./pages/Login";
import Registro from "./pages/Registro";
import Verificar from "./pages/Verificar";
import OlvideClave from "./pages/OlvideClave";

//Context
import { AuthContext } from "./contexts/AuthContext";


//Router
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
//Animations
import {AnimatePresence} from 'framer-motion';





function App() {
	const { user, isLoading } = useContext(AuthContext);
	const location = useLocation();


	const renderRoutes = () => {
		if(user.type == 'local' && !user.verified){
			return(
				<>	
					<Route path="/" element={<Verificar />} />
					<Route path="*" element={ <Navigate to="/" /> } />
				</>
			)
		}
		else{
			return(
				<>
					<Route path="/" element={<Home />} />
					<Route path="/reclamos" element={<Reclamos />} />
					<Route path="/avisos" element={<Avisos />} />
					<Route path="/ayuda" element={<Ayuda />} />
					<Route path="/nuevo" element={<NuevoReclamo />} />
					<Route path="*" element={ <Navigate to="/" /> } />
				</>
			)
		}
	}

	const renderNav = () => {
		if(location.pathname === "/" || location.pathname === "/avisos" || location.pathname === "/reclamos" ){
			if(user.type == 'local' && !user.verified){
				return null
			}

			return(<Nav />)
		}
		else return null
	}

	return (
		<div className="App">
			<GlobalStyle />
			
			{
				(!isLoading)
				?
				<AnimatePresence mode='wait' onExitComplete={() => window.scrollTo(0, 0)} >

					{
						(user)
						?
						<>
							<Header />
							<Routes location={location} key={location.pathname}>
								{renderRoutes()}
							</Routes>
							{ renderNav() }
						</>
						:
						<Routes location={location} key={location.pathname}>
							<Route path="/" element={<Login />} />
							<Route path="/olvideClave" element={<OlvideClave />} />
							<Route path="/registro" element={<Registro />} />
						</Routes>
					}
					

					
				</AnimatePresence>
				: <></>
			}
				

			
			
			
		</div>
	);
}

export default App;