import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { darker_cyan, darker_cyan2 } from "../colors";
import Icon from "@mdi/react";
import { mdiCamera, mdiImage, mdiClose } from "@mdi/js";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const FileUpload = ({ fotos, setFotos }) => {
	//const [fileList, setFileList] = useState([]);
    const [showCamera, setShowCamera] = useState(false);
	const inputRef = useRef(null);


	const handleFileChange = (e) => {
		const input_arr = e.target.files;
		let arr = [];
		for (let i = 0; i < input_arr.length; i++) {
			let f = input_arr[i];
		
			let filename = f.name;

			let file_id =
				filename + "_" + i + (Math.random() + Date.now()).toString(36);

            //para evitar estar llamando constantemente a getImageUrl en el render, lo hago una vez y guardo como propiedad "url"
			arr.push({
				id: file_id,
				data: f,
                url : getImageUrl(f) 
			});
		}

		setFotos(fotos.concat(arr));
	};

	const getImageUrl = (file) => {
		return URL.createObjectURL(file);
	};

    const removeFile = (id) => {
        var arr = [...fotos];
        let filtered = arr.filter((el)=>{
            return el.id != id
        })
        setFotos(filtered);
    };

    function handleTakePhoto (dataUri) {


        fetch(dataUri)
        .then(response => response.blob())
        .then(blob => {
            const file = new File([blob], "CAMERA_IMG.jpg", {type: blob.type})

            let file_id =
            file.name + "_" + (Math.random() + Date.now()).toString(36);
            let file_obj = {
                id : file_id,
                data : file,
                url : getImageUrl(file)
            }

            let arr_files = [...fotos];

            arr_files.push(file_obj);
            setFotos(arr_files);
            setShowCamera(false);
        })       
    }
    
	return (
		<div>
			<Row>
				<FotoButton onClick = {()=>{setShowCamera(true)}}>
					<FotoIcon
						path={mdiCamera}
						size={2}
						color="white"
					></FotoIcon>
					<b>Usar cámara</b>
				</FotoButton>
				<FotoButton onClick={() => inputRef.current.click()}>
					<FotoIcon path={mdiImage} size={2} color="white"></FotoIcon>
					<b>Abrir galería</b>
				</FotoButton>
			</Row>

			<ImageList>
				{fotos.length > 0 &&
					fotos.map((file, i) => (
						<ImagePreview key ={ file.id }>
							<img src={file.url}></img>
                            <DeleteImage onClick = {() => removeFile(file.id)}>
                                <Icon
                                    path={mdiClose}
                                    size={1}
                                    color="white"
                                ></Icon>
                            </DeleteImage>
						</ImagePreview>
					))}
			</ImageList>
			<input
				ref={ inputRef }
				id="files"
				type="file"
				multiple
				accept="image/*"
				autoComplete="off"
				onChange={ handleFileChange }
				style={{ display: "none" }}
			></input>
			
            { showCamera && 
			<CameraContainer>
				<CancelCameraButton onClick = {()=> { setShowCamera(false) }} >Cancelar</CancelCameraButton>
				<Camera
					onCameraStart={()=> console.log("started camera")}
					onCameraStop={ () => console.log("stopped camera ") }
					isFullscreen = { false }
					idealFacingMode = { FACING_MODES.ENVIRONMENT }
					onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri) } }
					imageType = { IMAGE_TYPES.JPG }
				/> 
			</CameraContainer>
			}
		</div>
	);
};



const Row = styled.div`
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
`;
const Button = styled.button`
	border-radius: 0.5rem;
	border-width: 0;
	color: white;
	padding: 1rem;
	display: flex;
	align-self: center;
	margin-bottom: 8px;
	margin-top: 8px;
	justify-content: center;
	cursor: pointer;
	font-weight: bold;
	font-size: 1.5rem;
	font-family: "Space Grotesk", sans-serif;
	-webkit-tap-highlight-color: transparent; /* Remover el color de resaltado al tocar en moviles*/
`;

const FotoButton = styled(Button)`
	flex-direction: column;
	background-color: ${darker_cyan2};
	&:hover {
		background-color: ${darker_cyan};
	}
`;

const FotoIcon = styled(Icon)`
	align-self: center;
`;


const CancelCameraButton = styled(Button)`
	background-color: ${darker_cyan2};
	&:hover {
		background-color: ${darker_cyan};
	}
	position : fixed;
	top : 1rem;
	right : 1rem;
	z-index: 11;
`;


const ImageList = styled.div`
	display: flex;
    flex-wrap: wrap;
	flex-direction: row;
	li {
		list-style: none;
	}
`;

const ImagePreview = styled.div`
	position : relative;
    width : 12rem;
    height : 12rem;
	/* width: calc((100vw / 3) - 3rem);
    height: calc((100vw / 3) - 3rem); */
	max-width: 12rem;
    max-height: 12rem;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    margin-right: 0.5rem;
    margin-left : 0.5rem;
    margin-bottom : 0.5rem;
    border-radius: 10px;
    img{
        width: 100%;
        height: 100%;
        object-fit: fit;
        border-radius: 10px;
    }
`;

const DeleteImage = styled.div`
    position : absolute;
    top : 0;
    right: 0;
    background-color: ${darker_cyan2};
    color :white;
    width: 2rem;
    height: 2rem;
    padding : 0;
    text-align: center;
    display : flex;
    justify-content: center;
    align-items: center;
    cursor : pointer;
    border-top-right-radius: 10px;
`;

const CameraContainer = styled.div`
	display : flex;
	position : fixed;
	top : 0;
	left : 0;
	justify-content: center;
	align-items:center;
	width : 100vw;
	height: 100vh;
	padding : 0;
	background: #FFFFFFDD;
	z-index : 10;
`;

export default FileUpload;
