import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import Icon from '@mdi/react';
import { mdiHomeVariantOutline, mdiHomeVariant, mdiBellOutline, mdiBell, mdiListBoxOutline, mdiListBox } from '@mdi/js';
import { iconAnim } from "../animation";
import { dark_cyan,darker_cyan,dark_cyan2,darker_cyan2, gamboge, gamboge2 } from "../colors"; 


const Nav = () => {
    const { pathname } = useLocation(); 
	

	return (
		<div>
			{/* <PhantomDiv /> */}
			<StyledNav>				
				<ul>
					<li style={{"background-color": (pathname === "/") ? darker_cyan : "transparent"}}>
						
						<StyledLink to="/">
							<StyledIcon
								//variants = {iconAnim}
								//initial="inactive"
								//animate={ pathname === "/" ? "active" : "inactive"} 
								path={ (pathname === "/") ? mdiHomeVariant : mdiHomeVariantOutline}
								title="Inicio"
								size={1.5}
							/>
							<b>Inicio</b>	
						</StyledLink>
						
					</li>
					<li style={{"background-color": (pathname === "/reclamos") ? darker_cyan : "transparent"}}>
						<StyledLink to="reclamos">
							<StyledIcon 
								path={ (pathname === "/reclamos") ? mdiListBox : mdiListBoxOutline }
								title="Reclamos"
								size={1.5}
								//variants = {iconAnim}
								//initial="inactive"
								//animate={ pathname === "/reclamos" ? "active" : "inactive"} 
							/>
							<b>Reclamos</b>
						</StyledLink>

						
					</li>
					<li style={{"background-color": (pathname === "/avisos") ? darker_cyan : "transparent"}}>
						<StyledLink to="avisos">
							<StyledIcon 
								path={(pathname === "/avisos") ? mdiBell :  mdiBellOutline}
								title="Avisos"
								size={1.5}
								//variants = {iconAnim}
								//initial="inactive"
								//animate={ pathname === "/avisos" ? "active" : "inactive"} 
							/>
							<b>Informes</b>
						</StyledLink>
						
					</li>
				</ul>
			</StyledNav>
		</div>
		
	);
};



const StyledNav = styled.nav`
	min-height: 10vh;
	display: flex;
	margin: auto;
	justify-content: space-between;
	align-items: center;
	padding: 0;
	background: ${darker_cyan2};
	position: fixed;
	bottom: 0;
	left : 0;
	width: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	box-shadow: 0px 5px 20px rgba(0,0,0,0.2);
	a {
		color: white;
		text-decoration: none;
	}
	ul {
		display: flex;
		flex : 3;
		list-style: none;
		padding : 0;
		margin: auto;
		li:first-child{
			border-top-left-radius: 10px;
		};
		li:last-child{
			border-top-right-radius: 10px;
		};
		li {
			position: relative;
			height : 100%;
			display : flex;
			padding: 1rem 10rem;
			flex : 1;
			justify-content: center;
		}
	}
	#logo {
		font-size: 1.5rem;
		font-family: "Helvetica", sans-serif;
		font-weight: lighter;
	}
	
	
	@media (max-width: 1300px) {
		ul {
			justify-content: space-around;
			width: 100%;
			li {
				padding: 1rem;
			}
			
		}
	}
`;

const StyledIcon = styled(motion(Icon))`
	display: flex;
	align-self: center;
	margin-bottom: 0.3rem;
	color : white;
`;

const StyledLink = styled(Link)`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	-webkit-tap-highlight-color: transparent; /* Remover el color de resaltado al tocar en moviles*/

`;

export default Nav;
