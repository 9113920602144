import React from 'react';
import styled from 'styled-components';
import { darker_cyan,darker_cyan2 } from '../colors';

const SeleccionarUbicacion = ({items,visible, setVisible, selectItem}) => {
    const renderItems = () => {
        return items.map((el) => {
            const txt = el.display_name.split(',')
            return(<Item onClick={ () => { 
                selectItem({
                    location : [el.lat,el.lon],
                    direccion : txt[1] + " " + txt[0]
                }); 
                setVisible(false) }}>
                    {txt[1] + " " + txt[0]}
                </Item>)
        }); 
    }


    return (
    visible &&<Modal>
        <h2>Resultados:</h2>
        <Container>
        {renderItems()}
        </Container>
        <CancelButton onClick={()=>{setVisible(false)}}>Cancelar</CancelButton>
      
    </Modal>
  )
}

const Modal = styled.div`
    display : flex;
	position : fixed;
	top : 0;
	left : 0;
	justify-content: center;
	align-items:center;
	width : 100vw;
	height: 100vh;
	padding : 2rem;
	background: #FFFFFFDD;
	z-index : 9000;
    flex-direction: column;
`;

const Container = styled.div`
    width : 98%;
    min-height: 400px;
    overflow-y: scroll;
    padding : 1rem;
    background-color: white;
    justify-content: center;
	align-items:center;
    display : flex;
    flex-direction: column;
    padding-top : 2rem;
    padding-bottom:2rem;
    
`

const Item = styled.div`
    font-size: 1rem;
    background-color: white;
    border-radius : 1rem;
    margin-top: 1rem;
    margin-bottom : 1rem;
    border: 1px solid lightgray;
    padding : 2rem;
    display : flex;
    width: 98%;
    justify-content: center;
    color : ${darker_cyan};
    &:hover{
        cursor : pointer
    }
`;

const Button = styled.button`
	border-radius: 0.5rem;
	border-width: 0;
	color: white;
	padding: 1rem;
	display: flex;
	align-self: center;
	margin-bottom: 8px;
	margin-top: 8px;
	justify-content: center;
	cursor: pointer;
	font-weight: bold;
	font-size: 1.5rem;
	font-family: "Space Grotesk", sans-serif;
	-webkit-tap-highlight-color: transparent; /* Remover el color de resaltado al tocar en moviles*/
`;

const CancelButton = styled(Button)`
	background-color: ${darker_cyan2};
	&:hover {
		background-color: ${darker_cyan};
	}
`;

export default SeleccionarUbicacion