import React from "react";
import styled from "styled-components";
import { dark_cyan,darker_cyan,dark_cyan2,darker_cyan2, gamboge, gamboge2 } from "../colors";
import Icon from "@mdi/react";
import { mdiCamera, mdiImage, mdiShape, mdiMapMarker, mdiText, mdiNote, mdiNoteEdit } from '@mdi/js';

const ConfirmarReclamo = ({handleButton, step, reclamo }) => {

    const enviar = () =>{
        alert(JSON.stringify(reclamo))
    }

    return(
        <Container>
            <h2>Confirmar el reclamo</h2>
            <Row>
               <Icon path = {mdiShape} size ={1}></Icon>
               <b>Tipo: <p>{reclamo.subtipo}</p></b>
            </Row>
            <Row>
                <Icon path = {mdiMapMarker} size ={1}></Icon>
               <b>Ubicación: <p>{reclamo.ubicacion.direccion}</p></b>
            </Row>
            <Row>
                <Icon path = {mdiNoteEdit} size ={1}></Icon>
               <b>Detalle: <p>{reclamo.descripcion}</p></b>
            </Row>
            <Row>
                <Icon path = {mdiCamera} size ={1}></Icon>
                <b>{ reclamo.fotos.length } fotos</b>
            </Row>
            <FotosRow>
               {
                reclamo.fotos.map( (f,i) => (<FotoThumb key={f.id}> <img src={f.url} width="100"></img> </FotoThumb> ))
               }
            </FotosRow>
            <Botonera>
                <CancelButton onClick ={ () => handleButton(step - 1) }> Cancelar</CancelButton>
                <SiguienteButton onClick={ enviar }>Continuar</SiguienteButton>
            </Botonera>
           
        </Container>
    );
};


export default ConfirmarReclamo;

const Container = styled.div`
    padding : 1rem;
    display : flex;
    flex-direction: column;
`;
const Button = styled.button`
    border-radius :0.5rem;
    border-width : 0;
    color : white;
    padding : 1rem;
    display : flex;
    align-self: center;
    margin-bottom: 8px;
    margin-top: 8px;
    justify-content: center;
    cursor : pointer;
    font-weight : bold;
    font-size: 1.5rem;
    font-family: 'Space Grotesk', sans-serif;
    -webkit-tap-highlight-color: transparent; /* Remover el color de resaltado al tocar en moviles*/
`;

const Botonera = styled.div`
    display : flex;
    position : fixed;
    bottom : 0;
    left : 0;
    width : 100vw;
    z-index: 10;
    flex-direction: row;
    justify-content: space-between;
    padding : 1rem;
`;


const Row = styled.div`
    padding : 1rem;
    display : flex;
    justify-content: flex-start;
    flex-direction : row;
`;

const SiguienteButton = styled(Button)`
    background-color: ${darker_cyan2};
    &:hover {
        background-color: ${darker_cyan};
    };
`;

const CancelButton = styled(Button)`
    color : black;
    background-color: white;
    &:hover {
        background-color: #BEBEBE;
    };
`;

const FotosRow = styled.div`
    padding : 1rem;
    display : flex;
    flex-direction : row;
    flex-wrap : wrap;
    justify-content : flex-start;
`;

const FotoThumb = styled.div`
    width: calc((100vw / 3) - 3rem );
    height : calc((100vw / 3) - 3rem );
    max-width: 12rem;
    max-height: 12rem;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    margin-right: 0.5rem;
    margin-left : 0.5rem;
    margin-bottom : 0.5rem;
    border-radius: 10px;
    img{
        width: 100%;
        height: 100%;
        object-fit: fit;
        border-radius: 10px;
    }

`;