import React from "react";
import styled from "styled-components";
//Page components

//Animations
import {motion} from 'framer-motion';
import {fade, pageAnimation, titleAnim} from '../animation';
import { dark_cyan,darker_cyan,dark_cyan2,darker_cyan2, gamboge, gamboge2 } from "../colors";   
import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiAlertDecagramOutline } from '@mdi/js';


const Home = () => {
	return (
		<HomeContainer /* variants={pageAnimation} initial="hidden" animate="show" exit="exit" */>
            <Welcome variants={fade} initial="hidden" animate="show">Te damos la bienvenida a Rosario</Welcome>
            <MainPhoto 
                src={"https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Plaza_25_de_Mayo_Rosario_2.jpg/1200px-Plaza_25_de_Mayo_Rosario_2.jpg"}
            ></MainPhoto>
            <StyledLink to="/nuevo?step=1">
                <NuevoButton> Iniciar un nuevo reclamo </NuevoButton>
            </StyledLink>	

            <InfoContainer variants={titleAnim} initial="hidden" animate="show">
                <InfoTitle>Informes</InfoTitle>
                <InfoDiv>
                    <InfoIconContainer>
                        <InfoIcon path={mdiAlertDecagramOutline} size={1.2}/>
                    </InfoIconContainer>
                    <InfoText> Lunes 22/03 no habrá recolección de residuos</InfoText> 
                    <Horario><HorarioText>09:30</HorarioText></Horario> 
                </InfoDiv>
                <InfoDiv>
                    <InfoIconContainer>
                        <InfoIcon path={mdiAlertDecagramOutline} size={1.2}/>
                    </InfoIconContainer>
                    <InfoText>
                        Corte de tránsito calle Alem altura Rivadavia.
                        Motivo: Arreglo de calzada.
                    </InfoText>
                    <Horario><HorarioText>10:22</HorarioText></Horario>
                </InfoDiv>
            </InfoContainer>
           
			{/* <AboutSection />
			<ServicesSection />
            <FaqSection /> */}
            
		</HomeContainer>
	);
};

export default Home;

const Welcome = styled(motion.h1)`
    margin:auto;
    display : flex;
    align-self: center;
    padding : 1rem;
    color : black;
    text-align: center;
    //text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
`;

const InfoTitle = styled.h2`
    margin:auto;
    display : flex;
    align-self: center;
    padding : 1rem;
    //text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    color : black;
    font-weight: bold;
    font-size : 1.6rem;
`;

const HomeContainer = styled(motion.div)`
    display : flex;
    flex-direction: column;
`;

const InfoContainer = styled(motion.div)`
    display : flex;
    flex-direction: column;
`;

const InfoDiv = styled.div`
    border-radius :10px;
    border-color : ${gamboge};
    border-width: 3px;
    border-style: solid;
    color : #050505;
    width: 90vw;
    font-size: 1.2rem;
    display : flex;
    align-self: center;
    margin-bottom: 0.3rem;
    align-content : center;
    position : relative;
    min-height : 64px;
    flex: 1;
    justify-content: space-between;
`;

const NuevoButton = styled(motion.button)`
    border-radius :0.5rem;
    border-width : 0;
    background-color: ${darker_cyan2};
    color : white;
    padding : 1rem;
    display : flex;
    align-self: center;
    margin-bottom: 8px;
    margin-top: 8px;
    justify-content: center;
    cursor : pointer;
    font-weight : bold;
    font-size: 1.6rem;
    font-family: 'Space Grotesk', sans-serif;
    width : 50vw;
    &:hover {
        background-color: ${darker_cyan};
    };
    @media (max-width: 1300px) {
		width : 90vw;
	};
`;

const StyledLink = styled(Link)`
    align-self: center;
    text-decoration: none;
`;

const MainPhoto = styled.img`
    width : 50vw;
    border-radius: 0.5rem;
    align-self: center;
    display: flex;
    margin:auto;
    height: 15rem;
    
    @media (max-width: 1300px) {
		width : 90vw;
	}
`;

const Horario = styled.div`
    position: relative;
    flex: 0.09;
    padding : 4px;
    display : flex;
    justify-content: flex-end;
    flex-direction: column;
`;

const HorarioText = styled.div`
    color : #5A5A5A;
`;

const InfoIconContainer = styled.div`
    flex: 0.07;
    flex-direction : column;
    justify-content: center;
    align-items: center;
    padding : 2px;
    display : flex;
`;

const InfoIcon = styled(Icon)`
   align-self: center;
`;

const InfoText = styled.div`
    flex : 0.82;
    text-overflow : ellipsis;
    text-align: left;
    align-items: center;
    display : flex;
`;


