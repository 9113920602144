import React from "react";
import styled from "styled-components";
import { useState } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiTree } from "@mdi/js";

//Animations
import { motion, AnimatePresence } from "framer-motion";

const Tipos = ({ items, step, handleButton, setSubtipo }) => {
	const [expanded, setExpanded] = useState(0);

	return items.map((i) => (
		<Accordion
			i={i.title}
			expanded={expanded}
			setExpanded={setExpanded}
			title={i.title}
			subtipos={i.subtipos}
			selectItem = { (item) => { setSubtipo(item.title); handleButton(step + 1) }}
		/>
	));
};

const Accordion = ({ i, expanded, setExpanded, title, subtipos, selectItem }) => {
	const isOpen = i === expanded;

	// By using `AnimatePresence` to mount and unmount the contents, we can animate
	// them in and out while also only rendering the contents of open accordions
	return (
		<Item
			initial={false}
			animate={{ backgroundColor: isOpen ? "#EEEEEE" : "white" }}
		>
			<ItemHeader onClick={() => setExpanded(isOpen ? false : i)}>
				<IconDiv>
					<Circle>
						<Icon path={mdiTree} size={1.5} color={"white"} />
					</Circle>
				</IconDiv>

				<ItemTitle>{title}</ItemTitle>
				<Icon path={mdiChevronDown} size={1.5} />
			</ItemHeader>

			<AnimatePresence initial={false}>
				{isOpen && (
					<motion.section
						key="content"
						initial="collapsed"
						animate="open"
						exit="collapsed"
						variants={{
							open: { opacity: 1, height: "auto" },
							collapsed: { opacity: 0, height: 0 },
						}}
						transition={{
							duration: 0.5,
							ease: [0.04, 0.62, 0.23, 0.98],
						}}
					>
						{subtipos.length &&
							subtipos.map((item) => {
								return (
									<SubItem
										variants={{
											collapsed: { scale: 0.8 },
											open: { scale: 1 },
										}}
										transition={{ duration: 0.5 }}
										onClick={ (item) => { 
											selectItem(item);
										}}
									>
										{item}
									</SubItem>
								);
							})}
					</motion.section>
				)}
			</AnimatePresence>
		</Item>
	);
};



export default Tipos;

const Item = styled(motion.div)`
	width: 100%;
	margin-bottom: 0.3rem;
	margin-top: 0.3rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-radius: 0.2rem;
	background-color: lightgray;
`;

const ItemHeader = styled(motion.header)`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	align-items: center;
`;

const ItemTitle = styled.h2`
	align-self: center;
	display: flex;
	align-content: center;
	text-align: center;
`;

const SubItem = styled(motion.div)`
	display: flex;
	padding: 1rem;
	background-color: white;
	margin-bottom: 0.1rem;
	margin-top: 0.1rem;
	border-radius: 0.2rem;
`;

const Circle = styled.div`
	height: 3rem;
	width: 3rem;
	background-color: darkcyan;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const IconDiv = styled.div`
	width: 4rem;
`;
