import React from "react";
import styled from "styled-components";
import { dark_cyan,darker_cyan,dark_cyan2,darker_cyan2, gamboge, gamboge2 } from "../colors";

const InfoSubTipo = ({step, handleButton}) => {
	return (
		<Container>
			<h2>Título del subtipo</h2>
            <InfoUl>
                <li>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                        varius feugiat dolor auctor aliquet. Pellentesque sollicitudin
                        elit ac lectus tincidunt varius. Duis dictum ultrices purus, vel
                        lacinia magna iaculis id. Nullam pulvinar tincidunt semper. Sed
                        maximus enim vel diam placerat iaculis. Mauris tincidunt
                        interdum dui, et fringilla nisi ultrices nec. Suspendisse vitae
                        nulla blandit leo rhoncus sagittis at vitae nibh.{" "}
                    </p>
                </li>
                <li>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                        varius feugiat dolor auctor aliquet. Pellentesque sollicitudin
                        elit ac lectus tincidunt varius. Duis dictum ultrices purus, vel
                        lacinia magna iaculis id. Nullam pulvinar tincidunt semper. Sed
                        maximus enim vel diam placerat iaculis. Mauris tincidunt
                        interdum dui, et fringilla nisi ultrices nec. Suspendisse vitae
                        nulla blandit leo rhoncus sagittis at vitae nibh.{" "}
                    </p>
                </li>
            </InfoUl>

            <Botonera>
                <CancelButton onClick ={ () => handleButton(step - 1) } >Cancelar</CancelButton>
                <SiguienteButton onClick ={ () => handleButton(step + 1)} >Continuar</SiguienteButton>
            </Botonera>
           
		</Container>
	);
};

const Container = styled.div`
    width : 98vw;
    padding : 1rem;
`;

const InfoUl = styled.ul`
    /* list-style-type: none; */
    li{
        margin-bottom :1rem;
        margin-top :1rem;
    }
    /* li:before {    
        font-family: 'FontAwesome';
        content: '\f067';
        margin:0 5px 0 -15px;
        color: #f00;
    } */
`;

const Botonera = styled.div`
    display : flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Button = styled.button`
    border-radius :0.5rem;
    border-width : 0;
    color : white;
    padding : 1rem;
    display : flex;
    align-self: center;
    margin-bottom: 8px;
    margin-top: 8px;
    justify-content: center;
    cursor : pointer;
    font-weight : bold;
    font-size: 1.5rem;
    font-family: 'Space Grotesk', sans-serif;
    -webkit-tap-highlight-color: transparent; /* Remover el color de resaltado al tocar en moviles*/
`;

const SiguienteButton = styled(Button)`
    background-color: ${darker_cyan2};
    &:hover {
        background-color: ${darker_cyan};
    };
`;

const CancelButton = styled(Button)`
    color : black;
    background-color: white;
    &:hover {
        background-color: #BEBEBE;
    };
`;

export default InfoSubTipo;


