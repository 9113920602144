import React, { useState } from "react";
import styled from "styled-components";
import { darker_cyan, darker_cyan2 } from "../colors";
import logo from "../img/logo.trim.png";
import axios from "axios";
import { Link } from "react-router-dom";

const OlvideClave = () => {
	const [enviado, setEnviado] = useState(false);
	const [enviadoReset, setEnviadoReset] = useState(false);
	const [email, setEmail] = useState("");
	const [pass, setPass] = useState("");
	const [newPass, setNewPass] = useState("");
	const [newPass2, setNewPass2] = useState("");
	const [mensaje, setMensaje] = useState("");
	const [error, setError] = useState("");
	const [exito, setExito] = useState(false);

	const enviar = () => {
		setMensaje("");
		setError("");
		setEnviado(true);
		axios({
			method: "POST",
			url: `${process.env.REACT_APP_SERVER_URL}/registro/olvideClave`,
			data: { email: email },
		})
			.then((res) => {
				console.log(res);
				if (res.data.success) {
					setMensaje(
						"Mensaje enviado, verificá tu casilla de correo."
					);
				}
			})
			.catch((er) => {
				setError(er.response.data.message);
				setEnviado(false);
				console.log(er);
			});
	};

	const resetPass = () => {
		setMensaje("");
		setEnviadoReset(true);
		setError("");
		axios({
			method: "POST",
			url: `${process.env.REACT_APP_SERVER_URL}/registro/restablecerClave`,
			data: {
				email: email,
				codigo: pass,
				pass: newPass,
				pass2: newPass2,
			},
		})
			.then((res) => {
				console.log(res);
				if (res.data.success) {
					setExito(true);
					setMensaje("Tu clave ha sido restablecida correctamente.");
				}
			})
			.catch((er) => {
				console.log(er);
				setEnviadoReset(false);
				setError(er.response.data.message);
			});
	};

	const handleChangeEmail = (e) => {
		setEmail(e.target.value);
	};
	const handleChangePass = (e) => {
		setPass(e.target.value);
	};
	const handleChangeNewPass = (e) => {
		setNewPass(e.target.value);
	};
	const handleChangeNewPass2 = (e) => {
		setNewPass2(e.target.value);
	};

	const valid = () => {
		let isValid = true;

		if (!pass.length || pass == "") isValid = false;
		if (!newPass.length || newPass == "") isValid = false;
		if (!newPass2.length || newPass2 == "") isValid = false;
		if (newPass != newPass2) isValid = false;

		return isValid;
	};

	return (
		<Container>
			<StyledLink to="/">
				<Logo src={logo}></Logo>
			</StyledLink>
			
			<InfoTitle>Restablecer tu clave.</InfoTitle>
			<p>
				Ingresá tu mail debajo y hacé clic en enviar, recibirás un
				código para restablecer tu clave.
			</p>
			<h3>{mensaje}</h3>

			{exito ? (
				<>
					<StyledLink to="/">
						<ContinuarButton onClick={resetPass}>
							Login
						</ContinuarButton>
					</StyledLink>
				</>
			) : (
				<>
					{!enviado && (
						<StyledInput>
							<input
								type={"email"}
								value={email}
								onChange={handleChangeEmail}
							/>
						</StyledInput>
					)}

					{!enviado && email && (
						<ContinuarButton onClick={enviar}>
							Enviar
						</ContinuarButton>
					)}

					{enviado && (
						<>
							<br style={{ marginTop: 3 }} />
							<StyledLabel>Código recibido:</StyledLabel>
							<StyledInput>
								<input
									type={"text"}
									value={pass}
									onChange={handleChangePass}
								/>
							</StyledInput>
							<StyledLabel>Nueva clave:</StyledLabel>
							<StyledInput>
								<input
									type={"password"}
									value={newPass}
									onChange={handleChangeNewPass}
								/>
							</StyledInput>
							<StyledLabel>Repetí la nueva clave:</StyledLabel>
							<StyledInput>
								<input
									type={"password"}
									value={newPass2}
									onChange={handleChangeNewPass2}
								/>
							</StyledInput>

							{valid() && !enviadoReset && (
								<ContinuarButton onClick={resetPass}>
									Restablecer
								</ContinuarButton>
							)}
						</>
					)}
				</>
			)}

			<h3 style={{ color: "darkred" }}>{error}</h3>
		</Container>
	);
};

const InfoTitle = styled.h2`
	margin: auto;
	display: flex;
	align-self: center;
	padding: 1rem;
	//text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
	color: black;
	font-weight: bold;
	font-size: 1.6rem;
`;
const Logo = styled.img`
	width: 8rem;
	margin: 1rem;
	display: flex;
	align-self: center;
`;

const StyledLabel = styled.div`
	padding: 0rem;
	display: flex;
	align-self: center;
	justify-content: flex-start;
	color: gray;
	font-family: "Space Grotesk", sans-serif;
	width: 30vw;
	max-width: 500px;
	position: relative;
	@media (max-width: 1040px) {
		width: 90vw;
		max-width: 90vw;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	p {
		font-size: 1.2rem;
		align-self: center;
	}
	h3 {
		margin-top: 3rem;
		align-self: center;
	}
	align-content: center;
	padding: 2rem;
	justify-content: center;
`;

const StyledInput = styled.div`
	border-radius: 0.5rem;
	border: 3px solid #bebebe;
	background-color: white;
	color: #050505;
	padding: 1rem;
	display: flex;
	align-self: center;

	justify-content: center;
	font-size: 1.6rem;
	font-family: "Space Grotesk", sans-serif;
	width: 30vw;
	max-width: 500px;
	position: relative;
	@media (max-width: 1040px) {
		width: 90vw;
		max-width: 90vw;
	}

	input {
		border: none;
		font-size: inherit;
		color: inherit;
		display: flex;
		flex: 5;
		text-align: center;
		color: ${darker_cyan};
		&:hover,
		:active,
		:focus {
			border: none;
			border-color: transparent;
			outline: none;
			outline-offset: 0;
			box-shadow: none;
		}
	}
`;

const ContinuarButton = styled.button`
	border-radius: 0.5rem;
	border-width: 0;
	background-color: ${darker_cyan2};
	color: white;
	padding: 1rem;
	display: flex;
	align-self: center;
	margin-bottom: 8px;
	margin-top: 8px;
	justify-content: center;
	cursor: pointer;
	font-weight: bold;
	font-size: 1.6rem;
	font-family: "Space Grotesk", sans-serif;
	width: 30vw;
	max-width: 500px;
	position: relative;
	&:hover {
		background-color: ${darker_cyan};
	}
	@media (max-width: 1040px) {
		width: 90vw;
		max-width: 90vw;
	}
`;

const StyledLink = styled(Link)`
    align-self: center;
    text-decoration: none;
`;

export default OlvideClave;
