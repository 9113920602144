import React, {useState} from "react";
import styled from "styled-components";
import { dark_cyan, darker_cyan,darker_cyan2 } from "../colors";
import Icon from "@mdi/react";
import { mdiEmailOutline, mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import logo from '../img/logo.trim.png';
import axios from "axios";
import { Link } from "react-router-dom";


const Registro = () => {
	const [verPass, setVerPass] = useState(false);
    const [verPass2, setVerPass2] = useState(false);
    const [loading,setLoading] = useState(false);
	const [userForm, setUserForm] = useState({
		username : '',
		password : '',
        repeatPassword : '',
	});
	const [formErrors, setFormErrors] = useState({
		username : '',
		password : '',
        repeatPassword : '',
		registerError : ''
	});
    const [success, setSuccess] = useState(false);

    const handleUsernameChange = (e) => {
		setUserForm({...userForm, username : e.target.value})
	};

	const handlePassChange = (e) => {
	    setUserForm({...userForm, password : e.target.value})
	};

    const handleRepeatPassChange = (e) => {
	    setUserForm({...userForm, repeatPassword : e.target.value})
	};

    const handleContinuarClick = () => {
        setLoading(true);
        setFormErrors({
            username : '',
            password : '',
            repeatPassword : '',
            registerError : ''
        });

        let valid = true;
		let errors = {...formErrors};

		if(userForm.username == ""){
			valid = false;
			errors.username = 'Ingrese un mail válido.';
		}

		if(userForm.password == ""){
			valid = false;
			errors.password = 'Ingrese la contraseña.';
		}

        if(userForm.repeatPassword != userForm.password){
			valid = false;
			errors.repeatPassword = 'Las contraseñas no coinciden.';
		}

		if(!valid){
            console.log(errors)
			setFormErrors(errors)
            setLoading(false);
			return;
		}

        axios({
			method : 'POST',
			url :`${process.env.REACT_APP_SERVER_URL}/registro/`,
			data : {
				username : userForm.username,
				password : userForm.password,
                repeatPassword : userForm.repeatPassword
			},
			withCredentials : true	
		})
		.then(res => {
			console.log(res)
			if(res){
				//window.location.replace('/?step=1')
                setSuccess(true);
			}
		})
		.catch(er => {
            setLoading(false);
			console.log(er)
			if(er.response.status === 400){
				setFormErrors({username : '', password : '', repeatPassword : '', registerError : er.response.data.message})
			}
		})
    };

    const render = () => {
        return(<Container>
            <Logo src={logo}></Logo>
            <Title>Elegí como ingresar a tu ciudad</Title>
			
			<StyledLabel>Email</StyledLabel>
			<StyledInput >
				<input type={'text'} value={userForm.username} onChange = { handleUsernameChange}/>
			</StyledInput>
			<ErrorMsg>{formErrors.username}</ErrorMsg>
			<div><br></br></div>
			<StyledLabel>Contraseña</StyledLabel>
			<StyledInput >
				<input type={(verPass)  ? 'text' : 'password'} value={userForm.password}  onChange = { handlePassChange}/>
				<LogoDiv>
					<StyledIcon path={(verPass) ? mdiEyeOutline : mdiEyeOffOutline } size={1.5} onClick={()=> { setVerPass(!verPass) }} ></StyledIcon>
				</LogoDiv>
				
			</StyledInput>
			<ErrorMsg>{formErrors.password}</ErrorMsg>
            <div><br></br></div>
            <StyledLabel>Repetí la contraseña</StyledLabel>
			<StyledInput >
				<input type={(verPass2)  ? 'text' : 'password'} value={userForm.repeatPassword}  onChange = { handleRepeatPassChange}/>
				<LogoDiv>
					<StyledIcon path={(verPass2) ? mdiEyeOutline : mdiEyeOffOutline } size={1.5} onClick={()=> { setVerPass2(!verPass2) }} ></StyledIcon>
				</LogoDiv>
				
			</StyledInput>
			<ErrorMsg>{formErrors.repeatPassword}</ErrorMsg>

			{!loading && <ContinuarButton onClick={handleContinuarClick}>Continuar</ContinuarButton>}
            { success && <SuccessMsg><h2>¡Registro exitoso! <StyledLink to="/?step=1"><Action> Iniciá sesión.</Action></StyledLink> </h2> </SuccessMsg>}
			<ErrorMsg>{formErrors.registerError}</ErrorMsg>
			
			<div>
				<br />
			</div>
			

			<StyledP>¿Ya tenes cuenta? <StyledLink to="/"><Action> Iniciá sesión.</Action></StyledLink></StyledP>
        </Container>);
    }

    return(
        render()
    )
}

export default Registro;

const Container = styled.div`
	display: flex;
	padding: 1rem;
	justify-content: center;
	flex-direction: column;
`;

const LoginButton = styled.div`
	border-radius: 0.5rem;
	border: 3px solid ${dark_cyan};
	background-color: white;
	color: #050505;
	padding: 1rem;
	display: flex;
	align-self: center;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
	justify-content: center;
	cursor: pointer;
	font-size: 1.6rem;
	font-family: "Space Grotesk", sans-serif;
    width : 30vw;
	max-width: 500px;
	position: relative;
	flex: 6;
	@media (max-width: 1040px) {
		width: 90vw;
        max-width: 90vw;
	}

	b {
	}
`;

const StyledIcon = styled(Icon)`
	margin-top: 4px;
	margin-right: 2rem;
`;




const Action = styled.b`
	color : ${dark_cyan};
	cursor : pointer;
`;



const Logo = styled.img`
    width: 12rem;
    margin : 2rem;
    display : flex;
    align-self: center;
`;


const Title = styled.h2`
	text-align: center;
	color : black;
	font-weight: bold;
	margin-top: 2rem;
	margin-bottom : 2rem;
`;

const StyledP = styled.p`
	text-align: center;
	margin-top : 5px;
	margin-bottom: 5px;
	font-size: 1.2rem;
	
`;

const StyledLabel = styled.div`
	padding: 0rem;
	display: flex;
	align-self: center;
	justify-content: flex-start;
	color : gray;
	font-family: "Space Grotesk", sans-serif;
    width : 30vw;
	max-width: 500px;
	position: relative;
	@media (max-width: 1040px) {
		width: 90vw;
        max-width: 90vw;
	}
`;

const StyledInput = styled.div`
	border-radius: 0.5rem;
	border: 3px solid #BEBEBE;
	background-color: white;
	color: #050505;
	padding: 1rem;
	display: flex;
	align-self: center;
	
	justify-content: center;
	font-size: 1.6rem;
	font-family: "Space Grotesk", sans-serif;
    width : 30vw;
	max-width: 500px;
	position: relative;
	@media (max-width: 1040px) {
		width: 90vw;
        max-width: 90vw;
	}

	input{
		border : none;
		font-size: inherit;
		color : inherit;
		display : flex;
		flex : 5;
		&:hover, :active, :focus{
			border : none;
			border-color: transparent;
			outline: none;
			outline-offset: 0;
			box-shadow: none;
		} 
	}
`;

const ContinuarButton = styled.button`
    border-radius :0.5rem;
    border-width : 0;
    background-color: ${darker_cyan2};
    color : white;
    padding : 1rem;
    display : flex;
    align-self: center;
    margin-bottom: 8px;
    margin-top: 8px;
    justify-content: center;
    cursor : pointer;
    font-weight : bold;
    font-size: 1.6rem;
    font-family: 'Space Grotesk', sans-serif;
    width : 30vw;
	max-width: 500px;
	position: relative;
    &:hover {
        background-color: ${darker_cyan};
    };
    @media (max-width: 1040px) {
		width : 90vw;
		max-width: 90vw;
	};
`;

const ErrorMsg = styled.div`
	padding: 0rem;
	display: flex;
	align-self: center;
	justify-content: flex-start;
	color : darkred;
	font-family: "Space Grotesk", sans-serif;
    width : 30vw;
	max-width: 500px;
	position: relative;
	@media (max-width: 1040px) {
		width: 90vw;
        max-width: 90vw;
	}
`;

const StyledLink = styled(Link)`
	text-decoration: none;
`;

const SuccessMsg = styled.div`
    display: flex;
	align-self: center;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
	justify-content: center;
    h2{
        color : ${dark_cyan}
    }
`;

const LogoDiv = styled.div`
	display: flex;
	flex: 1.5;
	justify-content: flex-start;
    @media (min-width: 1300px) {
		flex: 1.5;
        justify-content: center;
	}
`;