import React from "react";
import styled from "styled-components";
import FileUpload from "./FileUpload";
import { dark_cyan,darker_cyan,dark_cyan2,darker_cyan2, gamboge, gamboge2 } from "../colors";

const DetalleReclamo = ({handleButton, step, fotos, setFotos, descripcion, setDescripcion, direccion }) => {
    return(
        <Container>
            <h2>Nuevo reclamo en { direccion } </h2>
            <TextArea rows="10" placeholder="¿Cuál es el reclamo?" onChange={(e)=>{setDescripcion(e.target.value)}}>{descripcion}</TextArea>
            <FileUpload fotos={fotos} setFotos={ setFotos }/>
            <Botonera>
                <CancelButton onClick={ () => { handleButton(step -1 )} }> Cancelar</CancelButton>
                <SiguienteButton onClick={ () => { handleButton(step + 1)} }>Continuar</SiguienteButton>
            </Botonera>
           
        </Container>
    );
};


export default DetalleReclamo;

const Container = styled.div`
    padding : 1rem;
    display : flex;
    flex-direction: column;
`;
const Button = styled.button`
    border-radius :0.5rem;
    border-width : 0;
    color : white;
    padding : 1rem;
    display : flex;
    align-self: center;
    margin-bottom: 8px;
    margin-top: 8px;
    justify-content: center;
    cursor : pointer;
    font-weight : bold;
    font-size: 1.5rem;
    font-family: 'Space Grotesk', sans-serif;
    -webkit-tap-highlight-color: transparent; /* Remover el color de resaltado al tocar en moviles*/
`;

const Botonera = styled.div`
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    padding : 0rem 1rem;
    position : fixed;
    bottom : 0;
    left : 0;
    width : 100vw;
    z-index: 9;
    background-color: white;
`;




const TextArea = styled.textarea`
    padding : 1rem;
    width : 90vw;
    border-color: ${darker_cyan};
    border-radius: 10px;
    font-size : 1rem;
    font-weight: bold;
`;



const SiguienteButton = styled(Button)`
    background-color: ${darker_cyan2};
    &:hover {
        background-color: ${darker_cyan};
    };
`;

const CancelButton = styled(Button)`
    color : black;
    background-color: white;
    &:hover {
        background-color: #BEBEBE;
    };
`;