import React, {useState,useEffect,useContext} from "react";
import { AuthContext } from "../contexts/AuthContext";

//Page components
import Tipos from "../components/Tipos";
import Mapa from "../components/Mapa";
import InfoSubTipo from "../components/InfoSubTipo";
import DetalleReclamo from "../components/DetalleReclamo";
import ConfirmarReclamo from "../components/ConfirmarReclamo";

import styled from "styled-components";

//Animations
import {motion} from 'framer-motion';
import {pageAnimation} from '../animation';

//navigation
import { useSearchParams } from "react-router-dom";

const items = [{
    "title" : "Alumbrado",
    "subtipos" : ["luz rota", "falta luminaria"]
},
{
    "title" : "Arbolado",
    "subtipos" : ["luz rota", "falta luminaria"]
},
{
    "title" : "Calles, fachadas y veredas",
    "subtipos" : ["luz rota", "falta luminaria"]
},
{
    "title" : "Limpieza y recolección",
    "subtipos" : ["luz rota", "falta luminaria"]
}];

const NuevoReclamo = () => {
	
    const [searchParams, setSearchParams] = useSearchParams();

    const [ reclamo, setReclamo ] = useState({
        subtipo : null,
        ubicacion : { location : null , direccion : "" },
        descripcion : "",
        fotos : []
    })

    const setSubtipo = (subtipo) => {
        setReclamo({...reclamo, subtipo : subtipo })
    }

    const setUbicacion = (ubicacion) => {
        setReclamo({...reclamo, ubicacion : ubicacion })
    }

    const setDescripcion = (desc) => {
        setReclamo({...reclamo, descripcion : desc })
    }

    const setFotos = (fotos) => {
        setReclamo({...reclamo, fotos : fotos })
    }
    
	//useEffect
	useEffect( () => {
		navigator.geolocation.getCurrentPosition((position) => {
			setUbicacion({ location : [position.coords.latitude, position.coords.longitude], direccion : "" })	
		});
	},[])


    const goToStep = ( number ) => {
        setSearchParams({ 'step': number })
    }

    
    const renderStep = () => {
        switch(searchParams.get("step")){
            case "1":
                return <Tipos items={items} step={1} handleButton={goToStep} setSubtipo={ setSubtipo } subTipo = { reclamo.subtipo } />;
            case "2":
                return <InfoSubTipo step={2} handleButton={goToStep}/>;
            case "3":
                return <Mapa center = { reclamo.ubicacion.location || [-33.3312121,-60.2174602] } zoom={ 13 } step={3} handleButton={goToStep} ubicacion = {reclamo.ubicacion} setUbicacion={ setUbicacion }/>;
            case "4":
                return <DetalleReclamo step={4} handleButton={goToStep}  fotos = {reclamo.fotos} setFotos={ setFotos } descripcion = {reclamo.descripcion} setDescripcion={setDescripcion}/>
            case "5":
                return <ConfirmarReclamo step={5} handleButton={goToStep} reclamo = {reclamo} />
            default:
                return <></>; 
        }
    }

	return (
		<Container variants={pageAnimation} initial="hidden" animate="show" exit="exit">
           { renderStep() }
		</Container>
	);
};

export default NuevoReclamo;

const Container = styled(motion.div)`
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding : 1rem;
`;