import React from "react";
//Page components

//Animations
import {motion} from 'framer-motion';
import {pageAnimation} from '../animation';


const Reclamos = () => {
	return (
		<motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">	
			{/* <AboutSection />
			<ServicesSection />
            <FaqSection /> */}
            <h1>Reclamos</h1>
		</motion.div>
	);
};

export default Reclamos;