import axios from "axios";
import React,{useState,useEffect} from "react";
import styled from "styled-components";
import { darker_cyan2, darker_cyan } from "../colors";


const Verificar = () => {
    const [enviado,setEnviado] = useState(false);
    const [mensaje,setMensaje] = useState("");
	const [error,setError] = useState("");
	const [codigo,setCodigo] = useState("");

    const enviar = () => {
        setMensaje("");
        setEnviado(true);
        axios({
			method : 'POST',
			url :`${process.env.REACT_APP_SERVER_URL}/registro/enviarVerif`,
            withCredentials : true
		})
		.then(res => {
            console.log(res)
			if(res.data == 'OK'){
				setMensaje("Mensaje enviado, verificá tu casilla de correo e ingresá el código recibido");
			}
		})
		.catch(er => {
			console.log(er);
		})
    }

	const verificar = () => {
		axios({
			method : 'POST',
			url :`${process.env.REACT_APP_SERVER_URL}/registro/validarMail`,
            withCredentials : true,
            data : { token : codigo }
		})
		.then(res => {
            console.log(res)
			if(res.data == 'OK'){
				window.location.replace("/");
			}
		})
		.catch(er => {
            setError("Código inválido o expirado");
			console.log(er);
		})
	}

	const handleChangeCodigo = (e) => {
		setCodigo(e.target.value);
	}

	useEffect(()=>{
		if(codigo.length == 6){
			verificar();
		}
	},[codigo])

	return (
		<Container>
			<InfoTitle>Verificar tu correo.</InfoTitle>
			<p>
				Para continuar, se te enviará un código de verificación al mail
				registrado.
			</p>
			<p>El código expira en una hora, si no lo usás pasado este tiempo, deberás generar otro.</p>
            <h3>{mensaje}</h3>
			{!enviado && <ContinuarButton onClick={enviar}>Enviar código</ContinuarButton>}

			<StyledInput >
				<input maxlength="6" type={'text'} value={codigo} onChange = { handleChangeCodigo }/>
			</StyledInput>
			<h3 style={{ color : "darkred"}}>{error}</h3>
		</Container>
	);
};

export default Verificar;

const InfoTitle = styled.h2`
	margin: auto;
	display: flex;
	align-self: center;
	padding: 1rem;
	//text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
	color: black;
	font-weight: bold;
	font-size: 1.6rem;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	p {
		font-size: 1.2rem;
		align-self: center;
	}
    h3{
        margin-top :3rem;
        align-self: center;
    }
	align-content: center;
	padding: 2rem;
	justify-content: center;
`;

const StyledInput = styled.div`
	border-radius: 0.5rem;
	border: 3px solid #bebebe;
	background-color: white;
	color: #050505;
	padding: 1rem;
	display: flex;
	align-self: center;

	justify-content: center;
	font-size: 1.6rem;
	font-family: "Space Grotesk", sans-serif;
	width: 30vw;
	max-width: 500px;
	position: relative;
	@media (max-width: 1040px) {
		width: 90vw;
		max-width: 90vw;
	}

	input {
		border: none;
		font-size: inherit;
		color: inherit;
		display: flex;
		flex: 5;
		text-align: center;
		color : ${darker_cyan};
		&:hover,
		:active,
		:focus {
			border: none;
			border-color: transparent;
			outline: none;
			outline-offset: 0;
			box-shadow: none;
		}
	}
`;

const ContinuarButton = styled.button`
	border-radius: 0.5rem;
	border-width: 0;
	background-color: ${darker_cyan2};
	color: white;
	padding: 1rem;
	display: flex;
	align-self: center;
	margin-bottom: 8px;
	margin-top: 8px;
	justify-content: center;
	cursor: pointer;
	font-weight: bold;
	font-size: 1.6rem;
	font-family: "Space Grotesk", sans-serif;
	width: 30vw;
	max-width: 500px;
	position: relative;
	&:hover {
		background-color: ${darker_cyan};
	}
	@media (max-width: 1040px) {
		width: 90vw;
		max-width: 90vw;
	}
`;
