import { Marker, Popup, useMap } from 'react-leaflet'
import React, { useEffect } from 'react';

const MapMarker = ({coords}) => {
    const map = useMap();
    
    useEffect( () => {
        map.panTo(coords)
    });

    return( <Marker position={coords}>
        <Popup>
        A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
    </Marker>);
}

export default MapMarker;

