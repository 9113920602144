import React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Icon from '@mdi/react';
import { mdiCross,mdiMagnify, mdiStar } from '@mdi/js';
import axios from "axios";
import { dark_cyan, gamboge,darker_cyan } from "../colors";


const SearchAddress = ({searchCallback}) => {
    //State
    const [isSearching, setIsSearching] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [result, setResult] = useState(null);

    //functions
    const handleChange = (e) => {
        setSearchValue(e.target.value)
        return;
    }
    
    const handleBuscar = (e) => {
        setIsSearching(true);
        e.stopPropagation();
       
        axios.get(`https://geocode.maps.co/search?q=${searchValue}+san+nicolas+de+los+arroyos`)
        .then( (res) => {            
            setResult(res.data[0])
            if(res.data.length){
               /*  let ubicacion = {
                    location : [res.data[0].lat,res.data[0].lon],
                    direccion : res.data[0].display_name
                } */
                searchCallback(res.data)
            }
           
        })
        .catch( (er) => {
            console.log(er)
        })
        .finally(()=>{
            setIsSearching(false);
        })
    }

    //useEffect

    useEffect( () => {
        console.log(result)
    },[result])


	return (
        <Row>
            <StyledInput placeholder="Calle y altura" value={searchValue} onChange={handleChange}/>
            <SearchButton onClick={(e) => { handleBuscar(e) }} ><Icon path={mdiMagnify} size={0.75}/> </SearchButton>
        </Row>
        
	);
};



export default SearchAddress;


const StyledInput = styled.input`
    border : 1px solid gray;
    border-radius: 0.5rem;
    font-size: 1rem;
    padding : 1rem;
    display :flex;
    flex : 9;
`;

const SearchButton = styled.button`
    border-radius :0.5rem;
    border-width : 0;
    background-color: ${dark_cyan};
    color : white;
    padding : 1rem;
    display : flex;
    align-self: center;
    margin-bottom: 0.3rem;
    justify-content: center;
    cursor : pointer;
    font-weight:bold;
    margin-left: 1rem;
    font-family: 'Space Grotesk', sans-serif;
    flex: 1;
    &:hover {
        background-color: ${darker_cyan};
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding : 5px;
`;