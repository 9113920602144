import React, {useContext} from "react";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
import Icon from '@mdi/react';
import { mdiAccountCircle, mdiArrowLeft, mdiHelpCircleOutline } from '@mdi/js';
import logo from '../img/logo.trim.png';
import { gamboge } from "../colors";

import { AuthContext } from "../contexts/AuthContext";
//navigation
import { useSearchParams } from "react-router-dom";


const Header = ({step}) => {
	const [searchParams,setSearchParams] = useSearchParams();
    const { pathname } = useLocation(); 
	const { user, logout } = useContext(AuthContext);
	const goBack = () => {
		const number = searchParams.get('step')
		if(number <= 1 ) return window.location.replace('/')
        setSearchParams({ 'step': number - 1})
    }
	return (
		<div>
			
			{
				pathname === "/nuevo"
				?
				<StyledHeader>
					<Row>
						<Icon path={mdiArrowLeft} size={2} color="gray" onClick={goBack} />
						<HeaderTitle>Nuevo reclamo</HeaderTitle> 
					</Row>
					
				</StyledHeader>
				:
				<StyledHeader>				
					<Logo src={logo}></Logo>
					<RightContainer>
						
							<StyledLink to="ayuda">
								<Icon 
									path={mdiHelpCircleOutline}
									size={2}
									color={gamboge}
								/>
							</StyledLink>

							{
								 (user && user.photos.length) ? 
								 <UserImg src={user.photos[0].value} width="50" referrerPolicy="no-referrer" onClick={logout} />
								 : <Icon path={mdiAccountCircle} size={2} color={gamboge} onClick={logout} />
							}
							
						
					</RightContainer>
					
					
				</StyledHeader>


			}
			
		</div>
		
	);
};


const StyledHeader = styled.div`
	min-height: 10vh;
	display: flex;
	margin: auto;
    flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 10rem;
	background: white;
	position: sticky;
	top: 0;
	left : 0;
	z-index: 10;
	width: 100%;
	box-shadow: 0px 5px 20px rgba(0,0,0,0.2);
	a {
		color: black;
		text-decoration: none;
	}
	@media (max-width: 1300px) {
		padding: 0.1rem 0.2rem;
	}
`;

const StyledIcon = styled(Icon)`
	display: flex;
	align-self: center;
    margin-right: 1rem;
`;

const Logo = styled.img`
    width: 8rem;
    margin : 1rem;
    display : flex;
    align-self: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const HeaderTitle = styled.h2`
	color : gray;
	align-self: center;
	margin-left: 7rem;
`;

const RightContainer = styled.div`
	display : flex;
	flex-direction : row;
	justify-content: space-between;
	justify-items: flex-end;
`;

const StyledLink = styled(Link)`
	margin-right : 1rem;
	-webkit-tap-highlight-color: transparent; /* Remover el color de resaltado al tocar en moviles*/
`;

const UserImg = styled.img`
	height : 3rem;
	width: 3rem;
	border-radius: 50%;
	border :1px solid lightgray;
	padding:2px;
`;

export default Header;
